<template>
    <div style="background-color: white;">
      <overlay v-if="carsOverlay"/>
      <v-progress-linear
      indeterminate
      color="secondary"
      height="10"
      top
      :active="loaded"
      ></v-progress-linear>
      <v-container class="mt-5">
        <v-snackbar id="snackbar" top v-model="snackbar" :color="color" :timeout="5000">
          {{text}}
          <v-btn color="white" icon @click="snackbar = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-snackbar>
        <v-row class="mt-5" v-if="loaded === false && dataReady === true">
          <v-col cols="12" sm="4" md="4" lg="5" class="pa-0 my-5">
            <p class="display-1 secondary--text font-weight-bold mt-5">{{car.carInfo.carCategory}}, <span class="body-1 primary--text font-weight-bold">{{car.carInfo.carType}}</span></p>
            <img v-if="car.carInfo.images && car.carInfo.images.length > 0" :src="car.carInfo.images[0].Url" width="70%">
            <br>
            <img v-if="car.vendorLogo" :src="car.vendorLogo" alt="logo" width="100">
            <span v-if="!car.vendorLogo" class="body-1 primary--text font-weight-bold">{{car.vendorName}}</span>
            <br>
            <v-chip
            class="ma-1"
            color="secondary"
            outlined
            v-if="car.carInfo.carTrans"
            >
            <v-icon left>mdi-car-cog</v-icon>
            {{car.carInfo.carTrans}}
            </v-chip>
            <v-chip
            class="ma-1"
            color="secondary"
            outlined
            v-if="car.carInfo.seats && car.carInfo.seats > 0"
            >
            <v-icon left>mdi-car-seat</v-icon>
            {{car.carInfo.seats}} Seats
            </v-chip>
            <v-chip
            class="ma-1"
            color="secondary"
            outlined
            v-if="car.carInfo.carFuel"
            >
            <v-icon left>mdi-fuel</v-icon>
            {{car.carInfo.carFuel}}
            </v-chip>
            <v-chip
            class="ma-1"
            color="secondary"
            outlined
            v-if="car.carInfo.bags && car.carInfo.bags.length > 0"
            >
            <v-icon left>mdi-bag-checked</v-icon>
            <span v-for="(item, n) in car.carInfo.bags" :key="n"> . {{ item.Quantity}} {{item.Size}} bag/s </span>
            </v-chip>
            <br>
            <v-row>
              <v-col>
                <p class="caption primary--text">Pickup from: {{car.pickUpLocation}}</p>
                <p class="caption primary--text">Drop in: {{car.returnLocation}}</p>
                <div v-for="(fare, i) in car.fareInfo" :key="i">
                  <strong v-if="fare.ChargeType === 'totalPrice' && paymentType !== 'points'" class="display-1 primary--text font-weight-bold">${{fare.Amount}} Total</strong>
                  <strong v-if="fare.ChargeType === 'totalPrice' && paymentType === 'points'" class="display-1 primary--text font-weight-bold">$0</strong>
                </div>
              </v-col>
              <v-col>
                <div v-for="(fare, i) in car.fareInfo" :key="i">
                  <span class="caption-1 primary--text font-weight-bold">${{fare.Amount}}</span> {{fare.ChargeType}}, {{fare.MileageAllowance === 'UNL' ? 'Unlimited' : fare.MileageAllowance}} miles, <br>{{fare.MileageAllowance !== 'UNL' ? `Extra miles $${fare.ExtraMileageCharge}/mile` : ''}}
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="8" md="8" lg="7" class="form my-5">
            <v-form v-model="valid" ref="form" class="mt-5">
              <p v-if="!$cookies.isKey('userToken') || $store.state.user.role === 'admin' || $store.state.user.role === 'subAgent'" class="body-1 font-weight-bold secondary--text">Contact person details</p>
              <v-row>
                  <v-col cols="6" sm="6" class="py-0" v-if="!$cookies.isKey('userToken') || $store.state.user.role === 'admin' || $store.state.user.role === 'subAgent'">
                    <phoneInput @update="assignPhone" />
                  </v-col>
                  <v-col cols="6" sm="6" class="py-0" v-if="!$cookies.isKey('userToken') || $store.state.user.role === 'admin' || $store.state.user.role === 'subAgent'">
                    <v-text-field
                    outlined
                    v-model="email"
                    :rules="[v => !!v || 'E-mail is required', v => /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(v) || 'E-mail must be valid']"
                    label="E-mail"
                    required
                    prepend-inner-icon="mdi-email"
                    color="blue"
                    id="renter-name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <v-combobox
                    v-model="title"
                    :items="['Mr', 'Mrs', 'Ms', 'Miss']"
                    :search-input.sync="search"
                    prepend-inner-icon="mdi-card-account-details"
                    hide-selected
                    hint="Add title and press enter to append it"
                    label="Title"
                    outlined
                    :rules="[v => !!v || 'Title is required']"
                    class="pa-0"
                    height="56px"
                    color="blue"
                    id="renter-title"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                          <v-list-item-title>
                            No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                          </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <v-text-field
                    outlined
                    v-model="fName"
                    :rules="[v => (!!v && v.length > 1) || 'Item is required at least 2 characters', v => /^[A-Za-z][A-Za-z]*$/.test(v) || 'Name Must be letters only with no spaces']"
                    label="First name"
                    required
                    color="blue"
                    id="renter-fName"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <v-text-field
                    outlined
                    v-model="lName"
                    :rules="[v => (!!v && v.length > 1) || 'Item is required at least 2 characters', v => /^[A-Za-z][A-Za-z]*$/.test(v) || 'Name Must be letters only with no spaces']"
                    label="Last name"
                    required
                    color="blue"
                    id="renter-lName"
                    ></v-text-field>
                  </v-col>
                  <v-divider></v-divider>
                  <v-col cols="12" v-if="$store.state.user && $cookies.isKey('userToken')">
                    <v-radio-group row color="secondary" v-model="paymentType">
                      <v-radio id="payment-radio__credit" color="secondary" value="credit">
                        <template v-slot:label>
                          <small class="secondary--text">Credit Card</small>
                        </template>
                      </v-radio>
                      <v-radio id="payment-radio__cash" color="secondary" value="cash" v-if="$store.state.user.role !== 'customer'">
                        <template v-slot:label>
                          <span>Cash</span>
                        </template>
                      </v-radio>
                      <v-radio id="payment-radio__points" color="secondary" value="points" v-if="$store.state.user.role === 'customer'" :disabled="$store.state.user.points < $store.state.user.minPointsToRedeem || $store.state.user.pointsToAmount < Math.floor(totalPrice)">
                        <template v-slot:label>
                          <span v-if="$store.state.user.points < $store.state.user.minPointsToRedeem || $store.state.user.pointsToAmount < Math.floor(totalPrice)" class="secondary--text">Your Points aren't enough to cover this booking fees ({{$store.state.user.points.toLocaleString('en')}}p)</span>
                          <span v-else class="secondary--text">Your Points ({{$store.state.user.points.toLocaleString('en')}}p)</span>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" v-if="paymentType === 'points'">
                    <p class="body-1">You will be charged {{totalPrice * 170}} points </p>
                  </v-col>
                  <v-col cols="4" class="py-0" v-if="paymentType === 'credit'">
                    <v-text-field
                    outlined
                    v-model="zip"
                    :rules="[v => !!v || 'Item is required']"
                    label="ZipCode"
                    required
                    color="blue"
                    id="zip"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="8" class="py-0" v-if="paymentType === 'credit'">
                    <v-text-field
                    outlined
                    v-model="address"
                    :rules="[v => !!v || 'Item is required']"
                    label="Address"
                    required
                    color="blue"
                    prepend-inner-icon="mdi-map-marker"
                    id="address"
                    ></v-text-field>
                  </v-col>
                  <v-divider></v-divider>
                  <credit class="mx-1" v-if="paymentType === 'credit'" @holder="setCardHolder" @card="setCardData" @expire="setCardExpiry" @cvv="setCVV" />
                  <v-col cols="12" class="py-0">
                    <v-btn color="secondary" tile block :disabled="!valid" @click="rent" :loading="loading">Rent this car</v-btn>
                  </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <p v-if="loaded === false && dataReady === false" class="body-1 font-weight-bold primary--text mt-5">This car isn't available rightnow <router-link color="blue" to="/cars">search again</router-link></p>
        <div class="text-center">
          <v-dialog
          v-model="dialog"
          persistent
          width="500"
          >
            <v-card>
              <v-card-title
              class="headline primary white--text"
              >
              Rented Successfully
              </v-card-title>

              <v-card-text>
                <p class="body-1 primary--text font-weight-bold">Car rented successfully</p>
                <p class="body-1 primary--text font-weight-bold" v-if="responseData">Your Book Reference: {{responseData.booking_reference}}</p>
                <p class="body-1 primary--text font-weight-bold" v-if="responseData">Price: ${{responseData.price}}</p>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
              color="primary"
              tile
              @click="dialog = false; $router.push({ name: 'Home' })"
              >
                Finish
              </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-container>
    </div>
</template>

<script>
import { headersNoAuth, headers, showCar, rentCar } from '../links'
import overlay from '../components/overlay'
import credit from '../components/credit'
import phoneInput from '../components/mobile.vue'

export default {
  title: '-Car Book',
  components: {
    overlay,
    credit,
    phoneInput
  },
  data () {
    return {
      car: {},
      totalPrice: 0,
      phone: '',
      email: '',
      title: '',
      search: '',
      fName: '',
      lName: '',
      zip: '',
      paymentType: 'credit',
      address: '',
      creditNum: '',
      creditEx: '',
      creditType: '',
      cardHolder: '',
      loaded: true,
      dataReady: true,
      responseData: null,
      valid: true,
      loading: false,
      dialog: false,
      color: '',
      text: '',
      snackbar: false,
      carsTimeout: null,
      carsOverlay: false,
      cvv: ''
    }
  },
  methods: {
    assignPhone (phone) {
      this.phone = phone
    },
    setCardData (number, type) {
      this.creditNum = number
      switch (type) {
        case 'visa':
          this.creditType = 'VI'
          break
        case 'mastercard':
          this.creditType = 'CA'
          break
        case 'american-express':
          this.creditType = 'AX'
          break
        case 'discover':
          this.creditType = 'DS'
          break
        case 'diners-club':
          this.creditType = 'DN'
          break
        default:
          this.creditType = type
          break
      }
    },
    setCardExpiry (date) {
      this.creditEx = date
    },
    setCVV (cvv) {
      this.cvv = cvv
    },
    setCardHolder (name) {
      this.cardHolder = name
    },
    formatDate (date) {
      if (!date) return null
      const [year, month] = date.split('-')
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const newDate = `${months[month - 1]} ${year}`
      return newDate
    },
    rent () {
      this.loading = true
      this.$refs.form.validate()
      if (this.valid) {
        if ((!this.$cookies.isKey('userToken') && this.phone.valid) || (this.$cookies.isKey('userToken') && this.$store.state.user.role !== 'customer' && this.phone.valid) || (this.$cookies.isKey('userToken') && this.$store.state.user.role === 'customer')) {
          const body = {
            contact_phone: this.phone.nationalNumber,
            countryIsoCode: this.phone.countryCode,
            contact_email: this.email,
            driverTitle: this.title,
            driverFirstName: this.fName,
            driverLastName: this.lName,
            zipCode: this.zip,
            address: this.address,
            creditCardExpireDate: this.creditEx,
            creditCardNumber: this.creditNum,
            creditCardType: this.creditType,
            cardHolderName: this.cardHolder,
            cvv: this.cvv,
            pickupDate: this.$store.state.carInfo.pickup,
            pickupTime: this.$store.state.carInfo.pickupTime,
            returnDate: this.$store.state.carInfo.drop,
            returnTime: this.$store.state.carInfo.dropTime,
            search_id: this.$route.params.searchId,
            car_id: this.$route.params.carId
          }
          let header
          if (this.$cookies.isKey('userToken') && this.$store.state.user.role === 'customer') {
            delete body.contact_phone
            delete body.countryIsoCode
            delete body.contact_email
            header = {
              headers: headers(this.$cookies.get('userToken'))
            }
          } else if (this.$cookies.isKey('userToken') && this.$store.state.user.role !== 'customer') {
            header = {
              headers: headers(this.$cookies.get('userToken'))
            }
          } else header = { headers: headersNoAuth() }
          if (this.paymentType !== 'credit') {
            delete body.creditCardNumber
            delete body.creditCardExpireDate
            delete body.creditCardType
            delete body.cardHolderName
            delete body.cvv
            delete body.zipCode
            delete body.address
            this.paymentType === 'points' ? body.redeem = 'redeem' : body.cash = 'cash'
          }
          this.$http.post(rentCar, body, header).then(response => {
            if (response.body.status === true) {
              this.loading = false
              this.dialog = true
              this.responseData = response.body.data
              this.$store.dispatch('setCarsTimeOut', false)
              if (this.$store.state.carsTimeOutFunction) this.$store.dispatch('clearCarsTimeOutFunction')
              if (this.$store.state.carsSearchTimeOutFunction) this.$store.dispatch('clearCarsSearchTimeOutFunction')
            } else {
              this.loading = false
              this.snackbar = true
              this.color = 'error'
              this.text = response.body.message
            }
          }, () => {
            this.loading = false
            this.snackbar = true
            this.color = 'error'
            this.text = 'Something went wrong please try again in few minutes'
          })
        } else {
          this.snackbar = true
          this.color = 'error'
          this.text = 'Please provide a vaild mobile number'
          this.loading = false
        }
      } else {
        this.snackbar = true
        this.color = 'warning'
        this.text = 'Please fill all fields'
        this.loading = false
      }
    }
  },
  created () {
    window.scrollTo(0, 0)
    const term = `car_id=${this.$route.params.carId}&search_id=${this.$route.params.searchId}`
    this.$http.get(showCar(term), { headers: headersNoAuth() }).then(response => {
      if (response.body.status === true) {
        this.loaded = false
        this.dataReady = true
        this.car = response.body.data
        this.car.fareInfo.forEach(fare => {
          if (fare.ChargeType === 'totalPrice') this.totalPrice = fare.Amount
        })
      } else {
        this.loaded = false
        this.dataReady = false
      }
    })
    this.carsTimeout = setTimeout(() => {
      this.carsOverlay = true
    }, localStorage.getItem('carsTimer'))
  },
  beforeDestroy () {
    clearTimeout(this.carsTimeout)
    this.carsOverlay = false
  }
}
</script>

<style>
    .remove-controls input[type='number'] {
    -moz-appearance:textfield;
    }
    .remove-controls input::-webkit-outer-spin-button,
    .remove-controls input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    .form {
        border-left: 0.1px solid #d2d2d2
    }
    @media screen and (max-width: 599px) {
        .form {
            border-top: 0.1px solid #d2d2d2
        }
    }
</style>
